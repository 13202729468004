import { gql } from '@apollo/client';
import { MoneyFields } from '../moneyFields';

export const GET_COMPANY_SURPLUS_AMOUNTS = gql`
  query GetCompanySurplusAmounts($rut: String!) {
    getCompanySurplusBalance(rut: $rut)
  }
`;

export const CESSIONS_SUMMARY = gql`
  query cessionsSummary(
    $companyId: Int!
  ) {
    cessionsSummary(companyId: $companyId) {
      amount {
        ...MoneyFields
      }
      invoiceCount
      debtCount
      debtAmount {
        ...MoneyFields
      }
      availableSurplus {
        ...MoneyFields
      }
      cantWithdrawSurplus
    }
  }
  ${MoneyFields}
`;
