import { gql } from '@apollo/client';
import { CompanyFields, UserFields } from '../fragments';
import { UserIdentification } from './fragments';

export const LOGIN = gql`
  mutation TokenAuth(
    $email: String!
    $password: String!
    $utmCampaign: String
  ) {
    tokenAuth(email: $email, password: $password, utmCampaign: $utmCampaign) {
      payload
      refreshExpiresIn
      token
      refreshToken
      user {
        ...UserFields
      }
    }
  }
  ${UserFields}
`;

export const SII_CREDENTIAL_EXPERIMENT = gql`
  mutation SiiCredentialExperiment($companyRut: String!) {
    siiCredentialExperiment(companyRut: $companyRut) {
      success
    }
  }
`;

export const UPDATE_ACEPTA_USER_AND_ACCEPT_TERMS = gql`
  mutation updateAceptaUserAndAcceptTerms(
    $email: String!
    $phoneNumber: String!
    $password: String!
    $companyRutAcceptingTerms: String!
  ) {
    updateAceptaUserAndAcceptTerms(
      email: $email
      phoneNumber: $phoneNumber
      password: $password
      companyRutAcceptingTerms: $companyRutAcceptingTerms
    ) {
      token
      refreshToken
      user {
        email
        firstName
        lastName
        companyName
        phoneNumber
        companies {
          id
          rut
          invoiceProvider {
            id
            name
            integrated
            iconUrl
          }
        }
      }
      actualCompanyRut
      companies {
        rut
        name
        acceptedTerms
      }
    }
  }
`;

export const UPDATE_USER_INFO = gql`
  mutation UpdateUserInfo(
    $phoneNumber: String
    $firstName: String
    $lastName: String
    $rut: String
    $completeRut: String
    $mailProvided: String
  ) {
    updateUserInfo(
      phoneNumber: $phoneNumber
      firstName: $firstName
      lastName: $lastName
      rut: $rut
      completeRut: $completeRut
      mailProvided: $mailProvided
    ) {
      user {
        ...UserFields
      }
    }
  }
  ${UserFields}
`;

export const ACEPTA_LOGIN = gql`
  mutation AceptaAuth($rut: String!, $password: String!) {
    aceptaAuth(rut: $rut, password: $password) {
      token
      refreshToken
    }
  }
`;

export const ONBOARDING_AUTH = gql`
  mutation onboardingAuth($activationCode: String!, $email: String!) {
    onboardingAuth(activationCode: $activationCode, email: $email) {
      token
      refreshToken
    }
  }
`;

export const SENEGOCIA_AUTH = gql`
  mutation SenegociaAuth(
    $token: String!
    $loginFlag: String
    $isOrdering: Boolean!
  ) {
    senegociaAuth(
      token: $token
      loginFlag: $loginFlag
      isOrdering: $isOrdering
    ) {
      token
      refreshToken
    }
  }
`;

export const REGISTER_DEMO_STEP = gql`
  mutation registerDemoStep($clickedButton: String!) {
    registerDemoStep(clickedButton: $clickedButton) {
      response
    }
  }
`;

export const REGISTER_MOBILE_LOGIN = gql`
  mutation RegisterMobileLogin($companyRut: Int!) {
    registerMobileLogin(companyRut: $companyRut) {
      success
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($token: String!, $password: String!) {
    resetPassword(token: $token, password: $password) {
      success
    }
  }
`;

export const UPDATE_CONTACT_INFO = gql`
  mutation UpdateContactInfo($email: String!, $phoneNumber: String!) {
    updateContactInfo(email: $email, phoneNumber: $phoneNumber) {
      success
      message
    }
  }
`;

export const UPDATE_DUMMY_PASSWORD = gql`
  mutation UpdateDummyPassword(
    $newPassword: String!
    $mailProvided: String!
    $phoneNumber: String!
  ) {
    updateDummyPassword(
      newPassword: $newPassword
      mailProvided: $mailProvided
      phoneNumber: $phoneNumber
    ) {
      success
      message
      user {
        ...UserFields
      }
    }
  }
  ${UserFields}
`;

export const MARK_TUTORIAL_SEEN = gql`
  mutation MarkTutorialSeen($tutorialCode: String!) {
    markTutorialAsSeen(tutorialCode: $tutorialCode) {
      user {
        ...UserFields
      }
    }
  }
  ${UserFields}
`;

export const SET_PREFERENCES = gql`
  mutation setUserPreferences($input: UserPreferenceInputType!) {
    setUserPreferences(input: $input) {
      success
      message
    }
  }
`;

export const ADD_BANNED_COMPANY = gql`
  mutation addBannedCompany($companyRut: String!) {
    addBannedCompany(companyRut: $companyRut) {
      success
      message
    }
  }
`;

export const ERASE_BANNED_COMPANY = gql`
  mutation eraseBannedCompany($companyRut: String!) {
    eraseBannedCompany(companyRut: $companyRut) {
      success
      message
    }
  }
`;

export const REGISTER_USER_ACTIONS = gql`
  mutation CreateUserTrackMetric($actions: [UserActionType]!) {
    createUserTrackMetric(actions: $actions) {
      success
    }
  }
`;

export const USER_INTERACTION_REGISTER = gql`
  mutation userInteractionRegister($action: UserInteractionInputType!) {
    userInteractionRegister(action: $action) {
      interaction {
        id
      }
    }
  }
`;

export const REVOKE_REFRESH_TOKEN = gql`
  mutation RevokeToken($refreshToken: String!) {
    revokeToken(refreshToken: $refreshToken) {
      revoked
    }
  }
`;

export const ACEPTA_LOGIN_SESSION_ID = gql`
  mutation aceptaAuthSessionId(
    $sessionId: String!
    $buttonFlag: String
    $clientRut: String
    $companyRut: String
  ) {
    aceptaAuthSessionId(
      sessionId: $sessionId
      buttonFlag: $buttonFlag
      clientRut: $clientRut
      companyRut: $companyRut
    ) {
      token
      refreshToken
      actualCompanyRut
    }
  }
`;

export const UPDATE_NPS = gql`
  mutation UpdateNps(
    $id: ID!
    $grade: Int
    $reasons: [String]
    $otherReason: String
    $comment: String
    $lastShown: DateTime
    $location: String
  ) {
    updateNps(
      id: $id
      input: {
        grade: $grade
        reasons: $reasons
        otherReason: $otherReason
        comment: $comment
        lastShown: $lastShown
        location: $location
      }
    ) {
      updateNps {
        id
        grade
        reasons
        otherReason
        comment
      }
    }
  }
`;

export const SKIP_NPS = gql`
  mutation SkipNps($id: ID!) {
    skipNps(id: $id) {
      skipNps {
        id
        grade
        reasons
        otherReason
        comment
      }
    }
  }
`;

export const SEND_EMAIL_PASSWORD_RECOVERY = gql`
  mutation sendMail($email: String!) {
    sendEmailRecoveryPassword(email: $email) {
      success
    }
  }
`;

export const REGISTER_USER = gql`
  mutation RegisterUser(
    $email: String!
    $firstName: String
    $lastName: String
    $password: String!
    $phoneNumber: String
    $utmCampaign: String
    $origin: String
    $governmentIdentifier: String
    $governmentPassword: String
    $credentialProviderId: String
  ) {
    registerUser(
      email: $email
      firstName: $firstName
      lastName: $lastName
      password: $password
      phoneNumber: $phoneNumber
      utmCampaign: $utmCampaign
      origin: $origin
      governmentIdentifier: $governmentIdentifier
      governmentPassword: $governmentPassword
      credentialProviderId: $credentialProviderId
    ) {
      response
    }
  }
`;

export const REGISTER_STEP = gql`
  mutation RegisterStep(
    $step: Int!
    $loginType: String!
    $email: String!
    $errorInfo: String
    $firstName: String
    $lastName: String
    $phoneNumber: String
    $utmCampaign: String
    $origin: String
    $governmentIdentifier: String
    $governmentPassword: String
    $credentialProviderId: String
  ) {
    registerStep(
      step: $step
      loginType: $loginType
      errorInfo: $errorInfo
      email: $email
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      utmCampaign: $utmCampaign
      origin: $origin
      governmentIdentifier: $governmentIdentifier
      governmentPassword: $governmentPassword
      credentialProviderId: $credentialProviderId
    ) {
      response
    }
  }
`;

export const REGISTER_SENEGOCIA = gql`
  mutation SenegociaRegister(
    $token: String!
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $phoneNumber: String!
    $isOrdering: Boolean!
    $hasDummyPassword: Boolean!
  ) {
    senegociaRegister(
      token: $token
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
      phoneNumber: $phoneNumber
      isOrdering: $isOrdering
      hasDummyPassword: $hasDummyPassword
    ) {
      response
    }
  }
`;

export const GET_USER_TOKEN = gql`
  mutation getUserToken($userId: Int!, $tokenMinutes: Int) {
    getUserToken(userId: $userId, tokenMinutes: $tokenMinutes) {
      token
    }
  }
`;

export const CHANGE_COMPANY = gql`
  mutation changeCompany($companyId: Int!) {
    changeCompany(companyId: $companyId) {
      getUser {
        id
        selectedCompany {
          ...CompanyFields
        }
        permissions
      }
    }
  }
  ${CompanyFields}
`;

export const UPDATE_USER_GAME_HIGH_SCORE = gql`
  mutation updateUserGameHighScore($newScore: Int!) {
    updateUserGameHighScore(newScore: $newScore) {
      newHighScore {
        id
        createdAt
        updatedAt
        highScore
      }
    }
  }
`;

export const ASSIGN_EXECUTIVE_TO_COMPANY = gql`
  mutation assignExecutiveToCompany(
    $userId: String!
    $assignationType: String!
    $masterEntityId: String!
  ) {
    assignExecutiveToCompany(
      userId: $userId
      assignationType: $assignationType
      masterEntityId: $masterEntityId
    ) {
      masterEntity {
        id
        company {
          id
          executiveAssigned {
            ...UserIdentification
          }
        }
      }
    }
  }
  ${UserIdentification}
`;

export const SEND_EMAIL_VERIFICATION_MAIL = gql`
  mutation sendEmailVerificationMail {
    sendEmailVerificationMail {
      success
    }
  }
`;

export const SEND_PHONE_VERIFICATION_CODE = gql`
  mutation {
    sendPhoneVerificationCode {
      user {
        id
        hasActivePhoneVerificationCode @client
        phoneverification {
          id
          expirationDate
        }
      }
    }
  }
`;

export const VERIFY_EMAIL = gql`
  mutation verifyEmail($encodedEmail: String!, $token: String!) {
    verifyEmail(encodedEmail: $encodedEmail, token: $token) {
      user {
        id
        isEmailVerified
      }
    }
  }
`;

export const VERIFY_PHONE = gql`
  mutation verifyPhone($code: String!) {
    verifyPhone(code: $code) {
      user {
        id
        isPhoneVerified
      }
    }
  }
`;

export const NO_REGISTER_USER = gql`
  mutation noRegisterUser($phoneNumber: String!, $file: Upload!) {
    noRegisterUser(phoneNumber: $phoneNumber, file: $file) {
      success
    }
  }
`;

export const CONTACT_REQUEST = gql`
  mutation contactRequest(
    $phone: String!
    $email: String!
    $utmCampaign: String
    $encryptedMailLogId: String
  ) {
    contactRequest(
      phone: $phone
      email: $email
      utmCampaign: $utmCampaign
      encryptedMailLogId: $encryptedMailLogId
    ) {
      contactRequest {
        id
        phone
        email
      }
    }
  }
`;

export const OAUTH_LOGIN = gql`
  mutation oauth2Auth(
    $idToken: String!
    $provider: String!
    $utmCampaign: String
  ) {
    oauth2Auth(
      idToken: $idToken
      provider: $provider
      utmCampaign: $utmCampaign
    ) {
      payload
      refreshExpiresIn
      token
      refreshToken
    }
  }
`;

export const OTP_LOGIN = gql`
  mutation otpLogin($email: String!, $clientId: String!) {
    otpLogin(email: $email, clientId: $clientId) {
      success
    }
  }
`;

export const VERIFY_OTP = gql`
  mutation otpVerification($email: String!, $otp: String!) {
    otpVerification(email: $email, otp: $otp) {
      payload
      refreshExpiresIn
      token
      refreshToken
    }
  }
`;

export const UPLOAD_CONTACTABILITY_DOCUMENT_UPLOAD = gql`
  mutation contactabilityDocumentUpload($upload: Upload!) {
    contactabilityDocumentUpload(upload: $upload) {
      request {
        id
      }
    }
  }
`;
